








import { Component, Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import UiLoading from './UiLoading.vue';

@Component({
  components: {
    UiLoading,
  },
})
export default class UiCard extends BaseVue {
  @Prop({ default: 'tw-relative tw-px-6 tw-py-4 tw-rounded-lg tw-border tw-border-gray-200 tw-flex tw-flex-col' })
  public classes!: string;

  @Prop({ default: false })
  public isLoading!: boolean;

  public get computedClasses() {
    let classes = this.isLoading ? 'loading-container' : '';
    classes = classes + ' ' + this.classes;
    return classes;
  }
}
